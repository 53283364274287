import axios from "axios";
import { toast } from "react-toastify";

const backendURL = process.env.REACT_APP_BACKEND_URL;
// const backendURL = "http://localhost:8000";
// const backendURL = 'http://127.0.0.1:5001/apartments-new-backend/us-central1/default';

export const addApartment = async (token, userid, propertyData) => {
  const property = {
    user: userid,
    location: propertyData.location,
    img_links: propertyData.imgurl,
    web_link: propertyData.weburl,
    No_of_bedroom: propertyData.bedrooms,
    price: propertyData.price,
    featured_type: propertyData.featuredType,
    type_of_property: propertyData.propertyType,
    phone: propertyData.phone,
    email: propertyData.email,
    area:propertyData.area
  };

  const res = await axios
  .post(
    // "http://apartments-env.eba-ver32ppm.us-east-1.elasticbeanstalk.com/api/apartment/addApartment",
    `${backendURL}/api/apartment/addApartment`,
    property,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    )
    .catch((err) => {
      toast.error("Something Went Wrong", {
        position: "top-center",
        theme: "colored",
      });
    });
    
    // console.log(property,res);
    if (res.status !== 200 && res.status !== 201) {
      return console.log("error occured property not added");
  }
  console.log("property added succesfully");
  console.log(res);

  const data = await res.data;
  return data;
};

export const addBuilderProject = async (token, userid, propertyData) => {
  const property = {
    user: userid,
    location: propertyData.location,
    img_links: propertyData.imgurl,
    web_link: propertyData.weburl,
    No_of_bedroom: propertyData.bedrooms,
    number_Of_Floors: propertyData.floors,
    price: propertyData.price,
    featured_type: propertyData.featuredType,
    type_of_property: propertyData.propertyType,
    phone: propertyData.phone,
    email: propertyData.email,
    area:propertyData.area
  };

  const res = await axios
    .post(
      // "http://apartments-env.eba-ver32ppm.us-east-1.elasticbeanstalk.com/api/project/addProject",
      `${backendURL}/api/project/addProject`,
      property,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .catch((err) => {
      toast.error("Something Went Wrong", {
        position: "top-center",
        theme: "colored",
      });
    });

  if (res.status !== 200 && res.status !== 201) {
    return console.log("error occured property not added");
  }
  console.log("property added successfully");
  console.log(res);

  const data = await res.data;
  return data;
};

export const getUserApartment = async (token, userId) => {
  const res = await axios
    .get(
      // `http://apartments-env.eba-ver32ppm.us-east-1.elasticbeanstalk.com/api/apartment/getalluserApartment/${userId}`,
      `${backendURL}/api/apartment/getalluserApartment/${userId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .catch((err) => {
      console.log("user property did not got");
      toast.error("Something Went Wrong", {
        position: "top-center",
        theme: "colored",
      });
    });

  if (res.status !== 200 && res.status !== 201) {
    return console.log("error occured user property not received");
  }
  console.log("user property got succesfully");
  console.log(res);

  const data = await res.data;
  return data;
};

export const getApartmentByID = async (token, propertyId) => {
  const res = await axios
    .get(
      // `http://apartments-env.eba-ver32ppm.us-east-1.elasticbeanstalk.com/api/apartment/getappartment/${propertyId}`,
      `${backendURL}/api/apartment/getappartment/${propertyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )

    .catch((err) => {
      console.log("Editing property did not got");
      toast.error("Something Went Wrong getby Id", {
        position: "top-center",
        theme: "colored",
      });
    });

  if (res.status !== 200 && res.status !== 201) {
    return console.log("error occured editable property not received");
  }
  console.log("editable property got succesfully");
  console.log(res);

  const data = await res.data;
  return data;
};

export const updateApartmentByID = async (token, propertyId, editedData) => {
  const body = {
    id: propertyId,
    location: editedData.location,
    description: editedData.description,
    web_link: editedData.weburl,
    No_of_bedroom: editedData.bedrooms,
    price: editedData.price,
    featured_type: editedData.featuredType,
    type_of_property: editedData.propertyType,
    phone: editedData.phone,
    email: editedData.email,
  };

  const res = await axios
    .put(
      // "http://apartments-env.eba-ver32ppm.us-east-1.elasticbeanstalk.com/api/apartment/updateAppartment",
      `${backendURL}/api/apartment/updateAppartment`,
      body,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .catch((err) => {
      console.log(err);
      console.log("updated property did not got");
      toast.error("Something Went Wrong getby Id", {
        position: "top-center",
        theme: "colored",
      });
    });

  if (res.status !== 200 && res.status !== 201) {
    return console.log("error occured updated property not received");
  }
  console.log("updated property got succesfully");
  console.log(res);

  const data = await res.data;
  return data;
};

export const getAllApartments = async (body) => {
  const res = await axios
    .post(
      // "http://apartments-env.eba-ver32ppm.us-east-1.elasticbeanstalk.com/api/apartment/getallApartment",
      `${backendURL}/api/apartment/getallApartment`,
      body,
      {
        headers: {
          "Access-Control-Allow-Credentials": true,
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "POST",
          "Access-Control-Allow-Headers": "application/json",
        },
      }
    )
    .catch((err) => {
      console.log(err);
      console.log("All property did not get");
      toast.error("Something Went Wrong", {
        position: "top-center",
        theme: "colored",
      });
    });

  if (res.status !== 200 && res.status !== 201) {
    return console.log("error occured All property not received");
  }
  console.log("All property got succesfully");
  console.log(res);

  const data = await res.data;
  return data;
};

export const deleteApartmentByID = async (token, propertyId) => {
  const res = await axios
    .delete(
      // `http://apartments-env.eba-ver32ppm.us-east-1.elasticbeanstalk.com/api/apartment/deleteApartment/${propertyId}`,
      `${backendURL}/api/apartment/deleteApartment/${propertyId}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
    .catch((err) => {
      console.log("deleting property did not got");
      toast.error("Something Went Wrong getby Id", {
        position: "top-center",
        theme: "colored",
      });
    });

  if (res.status !== 200 && res.status !== 201) {
    return console.log("error occured deleting property not received");
  }

  const data = await res.data;
  return data;
};
