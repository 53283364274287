import { createClientMessage } from 'react-chatbot-kit';
import React from 'react';
import '../../chatbot.css'

const ActionProvider = ({ createChatBotMessage, setState, children }) => {

  const botMessage = async (msg, check) => {
    const message = createChatBotMessage(msg);
    updateState(message, check);
  }

   const updateState = (message, checker) => {
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
      checker,
    })) 
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            botMessage,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;

