import React from 'react'
import './email.css'
import { useNavigate } from 'react-router-dom'

const EmailVerified = (props) => {
  const navigate=useNavigate()
  const handleclick=()=>{
    navigate("/login")
    props.setVerificationpage(false)
  }
  return (
    <div className='emailContainer'>
      <div className="emailBox">
          <div className="emailBoxImg">
                   
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8b/Eo_circle_green_white_checkmark.svg/480px-Eo_circle_green_white_checkmark.svg.png" alt="" />
          </div>
          <div className="emailContent">
                <h2>Email Verified Succesfully</h2>
                <a className='emailLink' style={{fontSize:"30px"}} onClick={handleclick}>Login here</a>
          </div>
      </div>
    </div>
  )
}

export default EmailVerified
