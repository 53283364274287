import { createChatBotMessage } from 'react-chatbot-kit';
import BotAvatar from '../BotAvatar/BotAvatar';
import UserAvatar from '../BotAvatar/UserAvatar';
import StartBtn from '../Components/StartBtn';
import PdfUpload from '../Components/PdfUpload';
import Packages from '../Components/Packages'
import Themeswidget from '../Components/Themes'
import '../../chatbot.css'

const config = {
  initialMessages: [createChatBotMessage(`Hey buddy! I'm your virtual assistant!`, {
    widget: 'null',
  })
  , createChatBotMessage("In which city are you looking for an apartment?")
  ]
  ,
  checker: "city",
  botName: "Apartment Bot",
  customComponents: {
    botAvatar: (props) => <BotAvatar />,
    userAvatar: (props) => <UserAvatar />
  },

  // widgets: [
  //   {
  //     widgetName: 'startBtn',
  //     widgetFunc: (props) => <StartBtn {...props} />,

  //   },
  //   {
  //     widgetName: 'pdfUpload',
  //     widgetFunc: (props) => <PdfUpload {...props} />,
  //   },
  //   {
  //     widgetName: 'packages',
  //     widgetFunc: (props) => <Packages {...props} />,
  //   }
  //   ,
  //   {
  //     widgetName: 'themes',
  //     widgetFunc: (props) => <Themeswidget {...props} />,
  //   }
  // ]

};

export default config;