import React, { useState, useEffect } from "react";
import Header from "../header/Header";
import "./style.css";
import { Helmet } from "react-helmet";
import { useAuthContext } from "../context/hook/useAuthContext";

const Profile = ({configuration}) => {
  const { user, dispatch } = useAuthContext();
  const [userDetails, setUserDetails] = useState({
    name: "",
    email: "",
    location: "",
    priceLow: "",
    priceHigh: "",
    companyId: "",
  });

  const getData = (config) => {
      setUserDetails(config);
      getQuestions(config.companyId);
      getAnswers(config.email, config.companyId);
  };

  const [questions, setQuestions] = useState([]);
  const [userAnswers, setUserAnswers] = useState([]);

  const getQuestions = async (companyId) => {
    if (!companyId || companyId.length === 0) {
      console.log("No companyId found");
      return;
    }
    try {
      const response = await fetch(
        `https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/questions/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      if (response.ok) {
        console.log("Questions got successfully!", res);
        setQuestions(res.data);
        // let arr = [];
        // for(let question of res.data){
        //   arr.push({question,answer:""});
        // }
        // setUserAnswers(arr);
      } else {
        console.error("Failed to get questions. Server response:", res);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const getAnswers = async (userId, companyId) => {
    if (!companyId || !userId || companyId.length === 0) {
      console.log("No user or companyId found");
      return;
    }
    try {
      const response = await fetch(
        `https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/user-answers/${userId}/${companyId}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const res = await response.json();
      if (response.ok) {
        console.log("Answers got successfully!", res.answers);
        // let arr = [];
        // for(let obj of res.data){
        //   arr.push(obj);
        // }
        setUserAnswers(res.answers);
      } else {
        console.error("Failed to get questions. Server response:", res);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleAnswerChange = (question, event) => {
    // console.log(userAnswers);
    let updatedUserAnswers = [...userAnswers];
    const existingAnswerIndex = updatedUserAnswers.findIndex(
      (answer) => answer.question === question
    );

    if (existingAnswerIndex !== -1) {
      updatedUserAnswers[existingAnswerIndex].answer = event.target.value;
    } else {
      updatedUserAnswers.push({ question, answer: event.target.value });
    }

    // console.log(updatedUserAnswers);
    setUserAnswers(updatedUserAnswers);
  };

  const handleSaveAnswers = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(
        "https://us-central1-onboarding-bot-14200.cloudfunctions.net/expressApi/api/user-answers",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            userId: userDetails.email,
            companyId: userDetails.companyId,
            answers: userAnswers,
          }),
        }
      );

      const res = await response.json();
      if (response.ok) {
        console.log("User answers saved successfully!", res);
        alert("Answers saved successfully!");
      } else {
        console.error("Failed to save user answers. Server response:", res);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    if(configuration){
      getData(configuration);
    }
  }, [user,configuration]);

  return (
    <div className="container">
      <Header configuration={configuration}/>
      <h3 className="heading">
        Help us to enhance your experience by completing profile.
      </h3>
      {questions.length>0 ? <form className="formContainer">
        {questions.map((question, index) => {
          const correspondingAnswer = userAnswers.find(
            (answer) => answer.question === question
          );
          const defaultValue = correspondingAnswer
            ? correspondingAnswer.answer
            : "";

          return (
            <li
              key={index}
              className="label"
              style={{ width: "90%", listStyle: "none" }}
            >
              {question}
              <br />
              <input
                type="text"
                className="answers"
                name={`question${index}`}
                value={defaultValue}
                onChange={(event) => handleAnswerChange(question, event)}
              />
            </li>
          );
        })}

        <button className="btn" onClick={handleSaveAnswers}>
          Save
        </button>
      </form>:<p className="formContainer" style={{textAlign:'center', margin: "8px", fontSize: "1.5rem" }}>
                No results found
              </p>}

      <Helmet>
        <title>User Customization - Tailor Your Apartment Search</title>
        <meta
          name="description"
          content="Customize your apartment search with our builder form. Share details about your city preferences and price ranges. Tailor your search results to find the perfect apartments crafted by trusted builders in your desired locations."
        />
        <meta
          name="keywords"
          content="builder customization, personalized apartment search, city preferences, price range, tailor-made apartments, real estate developers, customized search results, personalized housing options"
        />
      </Helmet>
    </div>
  );
};

export default Profile;
