import React from "react";
import { Close } from "@mui/icons-material";

const PropertyAgent = () => {
  const handleSubmit = () => {};
  return (
    <div className="formcontainer">
      <form className="Authform" onSubmit={handleSubmit}>
        <div className="forminputs">
          <div className="closeicon">
            <span onClick={() => (window.location.href = "/")}>
              <Close sx={{ cursor: "pointer" }} />
            </span>
          </div>

          <h1>Property Agent SetUp</h1>

          <div className="emailinp">
            <label for="email">
              <b>Email</b>
            </label>
            <i
              className="fas fa-info-circle"
              title="Please enter the email which is used in apartments-sale.com. This is crucial due to data concerns."
            />
          </div>
          <input
            className="inputText"
            type="text"
            placeholder="Enter Email"
            // onChange={handleChange}
            // value={loginData.email}
            name="email"
            id="email"
            required
          />

          <div
            className="emailinp"
            style={{ display: "flex", alignItems: "center" }}
          >
            <label for="psw">
              <b>Domain Name</b>
            </label>
            <i
              className="fas fa-info-circle"
              title="This will be used as the domain name for your website. Please be careful this cannot be changed after confirmation."
            />
          </div>
          <input
            style={{ marginBottom: "0px" }}
            className="inputText"
            type="text"
            placeholder="Enter Domain Name"
            // onChange={handleChange}
            // value={loginData.password}
            name="password"
            id="password"
            required
          />
          <div className="signin">
            <button type="submit" className="btn">
              Submit
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default PropertyAgent;
