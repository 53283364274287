import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import Searchitem from "./searchitem/Searchitem";
import "./searchlist.css";
import "./searchitem/searchitem.css";
import { useAuthContext } from "../context/hook/useAuthContext";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllApartments } from "../api-helper/apartment";
import ArrowBackIosNewTwoToneIcon from "@mui/icons-material/ArrowBackIosNewTwoTone";
import ArrowForwardIosTwoToneIcon from "@mui/icons-material/ArrowForwardIosTwoTone";
import { Button } from "@mui/material";
import { TbBasketCancel } from "react-icons/tb";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";

const Filterlist = ({configuration}) => {
  const location = useLocation();
  const path = location.pathname;
  const city = path.substring(
    1,
    path[path.length - 1] === "/"
      ? path.length - 1
      : path.length
  );

  const title = `${city.toUpperCase()} Apartments for Sale - Find Your Dream Home`;
  const description = `Explore a variety of apartments for sale in ${city}. Find your dream home with our extensive listings. Discover the perfect living space for you and your family.`;
  const keywords = `${city} apartments, real estate, home listings, property, housing, buy apartment, real estate market`;

  const navigate = useNavigate();
  const { user, dispatch } = useAuthContext();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [currentCards, setCurrentCards] = useState([]);
  const cardsPerPage = 10;
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const getApartments = () => {
    getAllApartments({
      filter: { location: city },
      page: currentPage,
      limit: cardsPerPage,
    })
      .then((res) => {
        // console.log(res.data);
        setCurrentCards(res.data);
        setTotalItems(res.totalItems);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getApartments();
  }, [user, dispatch, currentPage]);

  useEffect(() => {
    console.log('Component is re-rendering. City:', city);
  }, [city]);

  const totalPages = Math.ceil(totalItems / cardsPerPage);

  return (
    <div>
      <Header configuration={configuration}/>
      <h1 style={{ fontWeight: 600, textAlign: "center", margin: "30px " }}>
      Explore a variety of apartments in <i style={{textTransform:'uppercase'}}>{city}</i>
      </h1>
      <div className="listcontainer">
        <div className="listwrapper">
          {currentCards.length > 0 ? (
            <div className="listresult">
              <button className="btnSearch" style={{marginTop:'25px', marginLeft:'20px'}} onClick={()=>{
                navigate('/map',{state:currentCards})
              }}>Explore these apartments on map</button>
              <div className="searchItem">
                {currentCards.map((val, ind) => (
                  <div key={ind}>
                    <Searchitem
                      property={val}
                      myproperty={false}
                      home={false}
                      configuration={configuration}
                    />
                  </div>
                ))}
              </div>
              <div className="pagination-buttons">
                <Button
                  variant="primary"
                  disabled={currentPage === 1}
                  onClick={handlePrevPage}
                >
                  <ArrowBackIosNewTwoToneIcon />
                </Button>
                <div className="page-info">
                  <p>
                    Page {currentPage} of {totalPages}
                  </p>
                </div>
                <Button
                  variant="primary"
                  disabled={currentPage === totalPages}
                  onClick={handleNextPage}
                >
                  <ArrowForwardIosTwoToneIcon />
                </Button>
              </div>
            </div>
          ) : (
            <div className="listresult not-found" style={{ height: "50vh" }}>
              <TbBasketCancel size={100} />
              <p style={{ margin: "8px", fontSize: "1.5rem" }}>
                No results found
              </p>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description} />
        <meta name="keywords" content={keywords} />
      </Helmet>
    </div>
  );
};

export default Filterlist;
