import React from "react";
import GoogleMapReact from 'google-map-react';

const MapContainer = (props) => {
    const mapProps = {
        center: {
          lat: props.lat,
          lng: props.lng
        },
        zoom: props.zoom
      };
    
      return (
        <div style={{ height: '20vh'}}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API }}
            center={mapProps.center}
            zoom={mapProps.zoom}
          >
          </GoogleMapReact>
        </div>
      );
};

export default MapContainer;
