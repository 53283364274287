import Chatbot from 'react-chatbot-kit';
import 'react-chatbot-kit/build/main.css';
import MessageParser from './bot/ChatBot/MessageParser.js';
import ActionProvider from './bot/ChatBot/ActionProvider.js';
import config from './bot/ChatBot/config';
import './chatbot.css'
import { BsFillChatRightDotsFill } from "react-icons/bs";
import { AiFillCloseCircle } from "react-icons/ai";
import { useState } from "react";

const ChatbotFile = () => {

  const [chatbotVisible, setChatbotVisible] = useState(false);

  const [botIconVisible, setbotIconVisible] = useState(false);

  const toggleChatbot = () => {
    setChatbotVisible(!chatbotVisible);
    setbotIconVisible(!botIconVisible);
  };



  return (
    <div className='chatbot'>

      {chatbotVisible &&
        <Chatbot
          className='cbct'
          id='cbt'
          config={config}
          messageParser={MessageParser}
          actionProvider={ActionProvider}
          style={{ zIndex: "1000" }}
        />}

      {!botIconVisible && <p style={{ position: 'fixed', right: '50px', top: '90vh', zIndex: "1000" }} onClick={toggleChatbot}><BsFillChatRightDotsFill className='mainbot' /></p>}
      {botIconVisible && <p style={{ position: 'fixed', right: 50, top: '90vh', zIndex: "1000" }} onClick={toggleChatbot}><AiFillCloseCircle className='mainbot2' /></p>}




    </div>
  );
}

export default ChatbotFile;