import React, { useState, useEffect } from "react";
import "./styles.css";
import { useLocation } from "react-router-dom";
import GoogleMapReact from "google-map-react";
import Marker from "./Marker";
import { getAllApartments } from "../../api-helper/apartment";
import { Helmet } from "react-helmet";

const Mapview = () => {
  const { state } = useLocation();
  // console.log(state);
  const [mapProps, setMapProps] = useState({
    center: {
      lat: 0,
      lng: 0,
    },
    zoom: 11,
  });

  const [points, setPoints] = useState([]);
  const [coordinates, setCoordinates] = useState(null);

  useEffect(() => {
    if (!coordinates && state && state.length > 0) {
      setPoints(state);
      setMapProps({
        zoom: mapProps.zoom,
        center: {
          lat: state[0].geoLocation.coordinates[1],
          lng: state[0].geoLocation.coordinates[0],
        },
      });
    } else {
      getAllApartments({
        filter: { coordinates },
        page: 1,
        limit: 1000,
      })
        .then((res) => {
          // console.log(res);
          setPoints(res.data);
          if (res.data.length) {
            setMapProps({
              zoom: mapProps.zoom,
              center: {
                lat: res.data[0].geoLocation.coordinates[1],
                lng: res.data[0].geoLocation.coordinates[0],
              },
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [coordinates]);

  const distanceToMouse = (pt, mp) => {
    if (pt && mp) {
      // return distance between the marker and mouse pointer
      let val = Math.sqrt(
        (pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y)
      );
      return val;
    }
  };

  return (
    <div className="mapContainer">
      <GoogleMapReact
        bootstrapURLKeys={{ key: process.env.REACT_APP_MAP_API }}
        center={mapProps.center}
        zoom={mapProps.zoom}
        distanceToMouse={distanceToMouse}
        onChange={({ center, zoom }) => {
          setMapProps({ center, zoom });
        }}
        onClick={(e) => {
          console.log(e.lng, e.lat);
          setCoordinates({ lat: e.lat, lng: e.lng, min: 0, max: 500000 });
        }}
      >
        {points.map((item) => {
          const { geoLocation, _id, name, location, price } = item;
          if(geoLocation && geoLocation.coordinates){
            return (
              <Marker
              key={_id}
              lat={geoLocation.coordinates[1]}
              lng={geoLocation.coordinates[0]}
              text={name}
              tooltip={`${location}, ${price}`}
              id={_id}
              />
              );
            }
        })}
      </GoogleMapReact>
      <Helmet>
        <title>Apartments Map - Explore Nearby Homes on the Map</title>
        <meta
          name="description"
          content="Discover apartments for sale on our interactive map. Click on coordinates to find nearby homes and explore the real estate options in your desired location. Start your journey to find your dream home today."
        />
        <meta
          name="keywords"
          content="apartments map, real estate map, property listings, interactive map, apartment search, housing options, nearby homes"
        />
      </Helmet>
    </div>
  );
};

export default Mapview;
