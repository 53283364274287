import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { addApartment, addBuilderProject } from "../../api-helper/apartment";
import { useAuthContext } from "../../context/hook/useAuthContext";
import Header from "../../header/Header";
import "./addproperty.css";
import ImageUploading from 'react-images-uploading';
import axios from "axios";

const AddProject = ({configuration}) => {
  const { user } = useAuthContext();
  let maxImages = 5;
  const [propertyData, setPropertyData] = useState({
    location: "",
    imgurl: [],
    weburl: "",
    bedrooms: "",
    floors: "",
    price: "",
    featuredType: "Family House",
    propertyType: "Rent",
    phone: "",
    email: "",
    area:""
  });
  const handleAddProperty = async (e) => {
    e.preventDefault();
    // console.log(propertyData);

    if (!propertyData.location || propertyData.location.trim() === "") {
      console.log("Loc empty");
      toast.error("Enter Location", {
        position: "top-center",
        theme: "colored",
      });
    } else if (propertyData.imgurl.length == 0) {
      propertyData.imgurl =
        ["http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg"];
    } else if (
      !propertyData.bedrooms ||
      propertyData.bedrooms.trim() === "" ||
      !propertyData.price ||
      propertyData.price.trim() === "" ||
      !propertyData.featuredType ||
      propertyData.featuredType.trim() === "" ||
      !propertyData.phone ||
      propertyData.phone.trim() === "" ||
      !propertyData.email ||
      propertyData.email.trim() === ""
    ) {
      console.log("some Fields are empty");
      // console.log(propertyData)
      toast.error("Enter All Fields", {
        position: "top-center",
        theme: "colored",
      });
    } else {
      let imageUrls = [];
      for (let image of propertyData.imgurl) {
        let formData = new FormData();
        formData.append("file", image['file']);
        formData.append("user_id", `apartments-sale/${user.existingUser._id}/projects/${propertyData.location}`);

        const res = await axios.post(
          `https://apartment-upload.web.app/api/uploads`,
          formData
        );

        const response = res.data;
        if (!response.success) {
          toast.error("Failed to upload photos", {
            position: "top-center",
            theme: "colored",
          });
          return;
        }
        imageUrls.push(response.imageObj.imageUrl);
      }

      let data = propertyData;
      data.imgurl = imageUrls;
      addBuilderProject(
        user.token,
        user.existingUser ? user.existingUser : user.user,
        data
      )
        .then((data) => {
          console.log("property add function works fine");
          console.log(data);
          toast.success("Property Added Successfully", {
            position: "top-center",
            theme: "colored",
          });
          setPropertyData({
            location: "",
            imgurl: [],
            weburl: "",
            bedrooms: "",
            price: "",
            featuredType: "Family House",
            propertyType: "Rent",
            phone: "",
            email: "",
            area:""
          });
        })
        .catch((err) => {
          console.log(err);
          console.log("porperty function error");
        });
    }
  };

  const handleChange = async (e) => {
    setPropertyData((prevState) => ({
      ...propertyData,
      [e.target.name]: e.target.value,
    }));
  };

  const onChange = (imageList, addUpdateIndex) => {
    // data for submit
    // console.log(imageList, addUpdateIndex);
    setPropertyData((prevState) => ({
      ...propertyData,
      imgurl: imageList,
    }));
  };

  return (
    <>
      <Header configuration={configuration}/>
      <div className="outerContainer">
        <div className="innerContainer">
          <div className="imgContainer">
            <img
              src="https://img.freepik.com/free-vector/house-rent-abstract-concept-illustration-booking-house-online-best-rental-property-real-estate-service-accommodation-marketplace-rental-listing-monthly-rent_335657-1123.jpg?w=2000"
              alt=""
            />
          </div>
          <div className="formContainer">
            <div className="addpropertytitle">
              <h1>Add Your Project</h1>
            </div>
            <form onSubmit={handleAddProperty} className="addpropertyForm">
              <div className="addpropertyItem">
                <p className="inputP">Location</p>
                <input
                  type="text"
                  name="location"
                  id=""
                  placeholder=""
                  onChange={handleChange}
                  value={propertyData.location}
                />
              </div>
              <div className="addpropertyItem">
                <p className="inputP">Images <span style={{ fontSize: "0.7rem", color: 'black' }}>(at most {maxImages})</span></p>
                <ImageUploading
                  multiple
                  value={propertyData.imgurl}
                  onChange={onChange}
                  maxNumber={maxImages}
                  dataURLKey="data_url"
                >
                  {({
                    imageList,
                    onImageUpload,
                    onImageRemove,
                    isDragging,
                    dragProps,
                  }) => (
                    // write your building UI
                    <div className="upload__image-wrapper">
                      <button
                        type="button"
                        style={isDragging ? { color: 'red' } : undefined}
                        onClick={onImageUpload}
                        {...dragProps}
                      >
                        Upload
                      </button>
                      &nbsp;
                      {imageList.map((image, index) => (
                        <div key={index} className="image-item">
                          <img src={image['data_url']} alt="" width="100" />
                          <div className="image-item__btn-wrapper">
                            <button type="button" onClick={() => onImageRemove(index)}>x</button>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </ImageUploading>
                <input
                  style={{height:'0px'}}
                  disabled
                  type="text"
                  name="imgurl"
                  id=""
                  placeholder=""
                  value=""
                />
              </div>
              <div className="addpropertyItem">
                <p className="inputP">Web Url</p>
                <input
                  type="text"
                  name="weburl"
                  id=""
                  placeholder=""
                  onChange={handleChange}
                  value={propertyData.weburl}
                />
              </div>
              <div className="addpropertyItem">
                <p className="inputP">Bedrooms</p>
                <input
                  type="text"
                  name="bedrooms"
                  id=""
                  placeholder=""
                  onChange={handleChange}
                  value={propertyData.bedrooms}
                />
              </div>
              <div className="addpropertyItem">
                <p className="inputP">Area</p>
                <input
                  type="text"
                  name="area"
                  id=""
                  placeholder=""
                  onChange={handleChange}
                  value={propertyData.area}
                />
              </div>
              <div className="addpropertyItem">
                <p className="inputP">Floors</p>
                <input
                  type="text"
                  name="floors"
                  id=""
                  placeholder=""
                  onChange={handleChange}
                  value={propertyData.floors}
                />
              </div>
              <div className="addpropertyItem">
                <p className="inputP">Price</p>
                <input
                  type="text"
                  name="price"
                  id=""
                  placeholder=""
                  onChange={handleChange}
                  value={propertyData.price}
                />
              </div>

              <div className="addpropertyItem">
                <p className="inputP">Featured Type</p>
                {/* <input type="text" name="featuredType" id="" placeholder='' onChange={handleChange} value={propertyData.featuredType}/> */}
                <select
                  className="typeOptions"
                  name="featuredType"
                  id=""
                  onChange={handleChange}
                  value={propertyData.featuredType}
                >
                  <option className="typeOptions" value="Family House">
                    Family House
                  </option>
                  <option className="typeOptions" value="House & Villa">
                    House & Villa
                  </option>
                  <option className="typeOptions" value="Apartment">
                    Apartment
                  </option>
                  <option className="typeOptions" value="Office & Studio">
                    Office & Studio
                  </option>
                  <option className="typeOptions" value="Villa & Condo">
                    Villa & Condo
                  </option>
                </select>
              </div>

              <div className="addpropertyItem">
                <p className="inputP" style={{ marginTop: "10px" }}>
                  Type of Property
                </p>
                <select
                  className="typeOptions"
                  name="propertyType"
                  id=""
                  onChange={handleChange}
                  value={propertyData.propertyType}
                >
                  <option className="typeOptions" value="Rent">
                    Rent
                  </option>
                  <option className="typeOptions" value="Sale">
                    Sale
                  </option>
                </select>
              </div>

              <div className="addpropertyItem" style={{ marginTop: "20px" }}>
                <p className="inputP">Phone</p>
                <input
                  type="text"
                  name="phone"
                  id=""
                  placeholder=""
                  onChange={handleChange}
                  value={propertyData.phone}
                />
              </div>

              <div className="addpropertyItem">
                <p className="inputP">Email</p>
                <input
                  type="email"
                  name="email"
                  id=""
                  placeholder=""
                  onChange={handleChange}
                  value={propertyData.email}
                />
              </div>

              <div className="addpropertyItem">
                <button
                  className="addbtn"
                  type="submit"
                  style={{ cursor: "pointer" }}
                >
                  Add Property
                </button>
              </div>
              <ToastContainer />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddProject;
