import React, { useEffect, useState } from "react";
import { getUserApartment } from "../../api-helper/apartment";
import { useAuthContext } from "../../context/hook/useAuthContext";
import Header from "../../header/Header";
import Searchitem from "../../searchlist/searchitem/Searchitem";
import "../../searchlist/searchlist.css";
import "../../searchlist/searchitem/searchitem.css";

const MyProperty = ({configuration}) => {
  const [userPropertyData, setuserPropertyData] = useState([{}]);

  const { user, dispatch } = useAuthContext();

  useEffect(() => {
    const getSearches = async () => {
      getUserApartment(
        JSON.parse(localStorage.getItem("user")).token,
        user.existingUser ? user.existingUser._id : user.user._id
      )
        .then((res) => {
          console.log("successfully got");
          setuserPropertyData(res.data);
          console.log(userPropertyData[0]._id);
        })
        .catch((err) => {
          console.log("got err");
          console.log(err);
        });
    };

    getSearches();
  }, [user, dispatch]);

  return (
    <>
      <Header configuration={configuration}/>
      <div style={{ background: "#f2f2f2" }} className="myPropertycontainer">
        <div className="listresult">
          <div className="searchItem">
            {userPropertyData.length === 0 ? (
              <p>You have not added any property still...</p>
            ) : (
              userPropertyData.map((val, ind) => (
                <div key={ind}>
                  <Searchitem property={val} myproperty={true} home={false} />
                </div>
              ))
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MyProperty;
