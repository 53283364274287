import React from "react";
import Header from "../../header/Header";
import Footer from "../footer/Footer";
import "./style.css";
import { Autoplay, Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { faMobileAlt } from "@fortawesome/free-solid-svg-icons";

import { Swiper, SwiperSlide } from "swiper/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

const IconHolder = (props) => {
  return (
    <div class="container-icon">
      <div class="icon">
        {props.icon}
        <i class="fas fa-icon"></i>
      </div>
      <div class="description">
        <p>{props.description}</p>
      </div>
    </div>
  );
};

const MoreInfo = () => {
  return (
    <main>
      <Header />
      <Swiper
        modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
        spaceBetween={10}
        slidesPerView={2}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
      >
        <SwiperSlide>
          <div className="item1">
            <img
              src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg"
              height={500}
            />
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="grid-container">
            <div className="item2">
              <img
                src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg"
                height={500}
              />
            </div>
            <div className="item2">
              <img
                src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg"
                height={500}
              />
            </div>
            <div className="item2">
              <img
                src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg"
                height={500}
              />
            </div>
            <div className="item2">
              <img
                src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg"
                height={500}
              />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
      <p style={{ width: "250px", color: "black", height: "12px" }} />
      <div class="container1">
        <div class="left1">
          <div class="content1">
            New York / Manhattan County / New York / Pod Pads
          </div>
          <h1>Pod Pads</h1>
          <h4>400 W 42nd St, New York, NY 10036</h4>
          <h4>Midtown West</h4>

          <div class="grid-container-new">
            <div class="grid-item">
              <p>Monthly Rent</p>
              <h3>$4,500 - $9,000</h3>
            </div>
            <div class="grid-item">
              <p>Monthly Rent</p>
              <h3>$4,500 - $9,000</h3>
            </div>
            <div class="grid-item">
              <p>Monthly Rent</p>
              <h3>$4,500 - $9,000</h3>
            </div>
            <div class="grid-item">
              <p>Monthly Rent</p>
              <h3>$4,500 - $9,000</h3>
            </div>
          </div>

          <p
            style={{
              width: "95%",
              backgroundColor: "black",
              height: "1px",
              margin: "20px 0px",
            }}
          />
          <div className="about">
            <h3 className="topic">About Pod Pads</h3>

            <p>
              Pod Pads are One & Two Bedroom Fully Furnished Apartments situated
              at the top of Pod Times Square Hotel. We offer Luxury Boutique
              convenience and hospitality with all the comforts of a home.
            </p>
            <p>
              Beautiful sun drenched One- & Two-Bedroom Pod Pads. Featuring
              floor-to-ceiling windows, elegant walnut floors, and panoramic
              city views. Kitchens fully stocked, offering Bertazzoni ovens and
              Blomberg appliances. Quartz countertops, and custom mill work.
              Two-Three Flat Screen TVs, one in each bedroom and living area.
              Marble bathrooms accoutered with top-of-the-line Hansgrohe
              fixtures and walk-in showers. Individual Climate Control in each
              room and living area. High Speed Wi-Fi & Premium Cable. Safe for
              valuables. Landline phone service with Free Calling. HSKP Services
              upon request. Minimum 30 Night Stay. Terms & Conditions apply.
            </p>
            <p>Rent payable by credit with Zero Processing Fees.</p>
            <p>
              Easy & simple National & International Approval & Lease Process.
              Monthly contracts can be completed in less than a day. Small
              Security Deposit. Pay first month upon arrival.
            </p>
            <p>
              Close to all the action, but just far enough away—and high
              above—the hustle and bustle.
            </p>
            <p>
              Pod Pads is an apartment community located in Manhattan County and
              the 10036 ZIP Code.
            </p>
          </div>

          <p
            style={{
              width: "95%",
              backgroundColor: "black",
              height: "1px",
              margin: "20px 0px",
            }}
          />

          <div>
            <h3 className="topic">Community Amenities</h3>

            <div style={{ display: "flex" }}>
              <IconHolder
                icon={<FontAwesomeIcon icon={faMobileAlt} />}
                description="Fitness Center"
              />
              <IconHolder
                icon={<FontAwesomeIcon icon={faMobileAlt} />}
                description="Fitness Center"
              />
              <IconHolder
                icon={<FontAwesomeIcon icon={faMobileAlt} />}
                description="Fitness Center"
              />
              <IconHolder
                icon={<FontAwesomeIcon icon={faMobileAlt} />}
                description="Fitness Center"
              />
            </div>
            <div>
              <ul class="grid-items">
                <li>Package Service</li>
                <li>Community-Wide WiFi</li>
                <li>Wi-Fi at Pool and Clubhouse</li>
                <li>Laundry Facilities</li>
                <li>Controlled Access</li>
                <li>Maintenance on site</li>
                <li>Property Manager on Site</li>
                <li>24 Hour Availability</li>
                <li>Furnished Units Available</li>
                <li>On-Site ATM</li>
                <li>Trash Pickup - Door to Door</li>
                <li>Key Fob Entry</li>
                <li>Elevator</li>
                <li>Lounge</li>
                <li>Fitness Center</li>
                <li>Roof Terrace</li>
                <li>Private Bathroom</li>
              </ul>
            </div>
          </div>

          <p
            style={{
              width: "95%",
              backgroundColor: "black",
              height: "1px",
              margin: "20px 0px",
            }}
          />
          <div>
            <h3 className="topic">Apartment Features</h3>

            <div style={{ display: "flex" }}>
              <IconHolder
                icon={<FontAwesomeIcon icon={faMobileAlt} />}
                description="Fitness Center"
              />
              <IconHolder
                icon={<FontAwesomeIcon icon={faMobileAlt} />}
                description="Fitness Center"
              />
              <IconHolder
                icon={<FontAwesomeIcon icon={faMobileAlt} />}
                description="Fitness Center"
              />
              <IconHolder
                icon={<FontAwesomeIcon icon={faMobileAlt} />}
                description="Fitness Center"
              />
            </div>
            <div style={{ display: "flex", marginTop: "20px" }}>
              <IconHolder
                icon={<FontAwesomeIcon icon={faMobileAlt} />}
                description="Fitness Center"
              />
              <IconHolder
                icon={<FontAwesomeIcon icon={faMobileAlt} />}
                description="Fitness Center"
              />
              <IconHolder
                icon={<FontAwesomeIcon icon={faMobileAlt} />}
                description="Fitness Center"
              />
              <IconHolder
                icon={<FontAwesomeIcon icon={faMobileAlt} />}
                description="Fitness Center"
              />
            </div>

            <h3 style={{ margin: "20px 0px" }}>Highlights</h3>
            <div>
              <ul className="grid-items">
                <li>High Speed Internet Access</li>
                <li>Wi-Fi</li>
                <li>Air Conditioning</li>
                <li>Heating</li>
                <li>Ceiling Fans</li>
                <li>Smoke Free</li>
                <li>Cable Ready</li>
                <li>Wheelchair Accessible (Rooms)</li>
              </ul>
            </div>
          </div>

          <p
            style={{
              width: "95%",
              backgroundColor: "black",
              height: "1px",
              margin: "20px 0px",
            }}
          />
          <div>
            <h3 style={{ margin: "20px 0px" }}>
              Kitchen Features & Appliances
            </h3>
            <div>
              <ul className="grid-items">
                <li>Dishwasher</li>
                <li>Stainless Steel Appliances</li>
                <li>Kitchen</li>
                <li>Microwave</li>
                <li>Oven</li>
                <li>Range</li>
                <li>Refrigerator</li>
                <li>Freezer</li>
                <li>Coffee System</li>
              </ul>
            </div>
          </div>
          <p
            style={{
              width: "95%",
              backgroundColor: "black",
              height: "1px",
              margin: "20px 0px",
            }}
          />
          <div>
            <h3 style={{ margin: "20px 0px" }}>Floor Plan Details</h3>
            <div>
              <ul className="grid-items">
                <li>Hardwood Floors</li>
                <li>Double Pane Windows</li>
                <li>Window Coverings</li>
                <li>Furnished</li>
              </ul>
            </div>
          </div>
          <p
            style={{
              width: "95%",
              backgroundColor: "black",
              height: "1px",
              margin: "20px 0px",
            }}
          />
          <div className="about">
            <h3 className="topic">Neighborhood</h3>

            <p>
              Stretching from 6th Ave. to the West Side Highway, and up from
              38th St. to Central Park South, Midtown West wraps up all you love
              about New York in the avenues leading up to the Hudson River.
            </p>
            <p>
              The obvious tourist attractions reside in Midtown West, including
              Times Square and Rockefeller Center, but for residents who want to
              miss the crowds in this urban locale, look closer to the water for
              popular spots like Industry Bar and Totto Ramen. However, renters
              and tourists alike will appreciate the lights and sights of
              Broadway, and the recognizable architecture of old New York.
              Pocket parks dot the grid of Manhattan, easing the inescapable
              buzz of the city.
            </p>
            <p>
              Like the rest of Midtown, Midtown West is a major business
              district. It’s a convenient place to rent if you work in the area
              and you want to avoid a long subway commute or prefer to walk.
            </p>
          </div>
          <p
            style={{
              width: "95%",
              backgroundColor: "black",
              height: "1px",
              margin: "20px 0px",
            }}
          />
          <div>
            <h3 className="topic">Pad Photos</h3>
          </div>

          <Swiper
            modules={[Navigation, Autoplay, Pagination, Scrollbar, A11y]}
            spaceBetween={10}
            slidesPerView={1}
            autoplay={{
              delay: 2000,
              disableOnInteraction: false,
            }}
          >
            <SwiperSlide>
              <div className="grid-container-pods">
                <div className="item2">
                  <img src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg" />
                </div>
                <div className="item2">
                  <img src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg" />
                </div>
                <div className="item2">
                  <img src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg" />
                </div>
                <div className="item2">
                  <img src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg" />
                </div>
                <div className="item2">
                  <img src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg" />
                </div>
                <div className="item2">
                  <img src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg" />
                </div>
                <div className="item2">
                  <img src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg" />
                </div>
                <div className="item2">
                  <img src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg" />
                </div>
                <div className="item2">
                  <img src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg" />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="grid-container-pods">
                <div className="item2">
                  <img src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg" />
                </div>
                <div className="item2">
                  <img src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg" />
                </div>
                <div className="item2">
                  <img src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg" />
                </div>
                <div className="item2">
                  <img src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg" />
                </div>
                <div className="item2">
                  <img src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg" />
                </div>
                <div className="item2">
                  <img src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg" />
                </div>
                <div className="item2">
                  <img src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg" />
                </div>
                <div className="item2">
                  <img src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg" />
                </div>
                <div className="item2">
                  <img src="http://storage.googleapis.com/a3_visual/apartments/tower-3.jpg" />
                </div>
              </div>
            </SwiperSlide>
          </Swiper>

          <p
            style={{
              width: "95%",
              backgroundColor: "black",
              height: "1px",
              margin: "20px 0px",
            }}
          />
          <section class="details">
            <h1 className="topic">Frequently Asked Questions</h1>
            <details>
              <summary class="summary">
                What neighborhood is the property located in?
              </summary>
              <p class="summaryptag">
                Pod Pads is in the city of New York. Here you’ll find three
                shopping centers within 1.4 miles of the property.Five parks are
                within 1.3 miles, including Bryant Park, Chelsea Park, and
                Chelsea Recreation Center.
              </p>
            </details>
            <details>
              <summary class="summary">
                Can I see a model or tour Pod Pads?
              </summary>
              <p class="summaryptag">
                You can take a virtual tour of Pod Pads on Apartments.com.
              </p>
            </details>
            <details>
              <summary class="summary">
                Which floor plans are available, and what are the price ranges?
              </summary>
              <p class="summaryptag">
                Pod Pads has one to two bedrooms with rent ranges from
                $4,500/mo. to $9,000/mo.
              </p>
            </details>
          </section>
        </div>
        <div class="right1">
          <h3>Contact this property</h3>
          <p>Tour Options: In-Person</p>
          <button>Request Tour</button>
          <div>
            <button>Send Message</button>
          </div>
          <a href="(973) 798-9644">
            <FontAwesomeIcon icon={faMobileAlt} />
            (973) 798-9644
          </a>
          <p
            style={{
              width: "300px",
              backgroundColor: "black",
              height: "1px",
              margin: "20px 0px",
            }}
          />
          <p>Language: English and Spanish</p>
          <p> Open 10am - 5pm Today</p>
          <p>View All Hours</p>
        </div>
      </div>{" "}
      <Footer />
    </main>
  );
};

export default MoreInfo;
