import React, { useEffect, useState } from "react";
import RecentCard from "./RecentCard";
import recent from "./recent.css";
import { getAllApartments } from "../../api-helper/apartment";
import Searchitem from "../../searchlist/searchitem/Searchitem";
import ArrowBackIosNewTwoToneIcon from "@mui/icons-material/ArrowBackIosNewTwoTone";
import ArrowForwardIosTwoToneIcon from "@mui/icons-material/ArrowForwardIosTwoTone";
import { Button } from "@mui/material";

const Recent = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [apartments, setApartments] = useState([]);

  const cardsPerPage = 18;
  const totalPages = Math.ceil(apartments.length / cardsPerPage);

  useEffect(() => {
    getAllApartments({filter:{},page:0,limit:50})
      .then((res) => {
        console.log("all property received");
        console.log(res.data);
        setApartments(res.data);
      })
      .catch((err) => {
        console.log("err in fetching all apartment");
      });
  }, []);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const startIndex = (currentPage - 1) * cardsPerPage;
  const endIndex = startIndex + cardsPerPage;
  const displayedApartments = apartments.slice(startIndex, endIndex);

  return (
    <section className="recent padding" id="About">
      <div className="container">
        <div className="recenttitle">
          <h1>Recent Property Listed</h1>
          <p>Get Started by going through the below listed apartments.</p>
        </div>
        <div className="content grid3 mtop">
          {displayedApartments.map((val, ind) => (
            <div className="shadow box" key={ind}>
              <Searchitem property={val} myproperty={false} home={true} />
            </div>
          ))}
        </div>
        <div className="pagination-buttons">
          <Button
            variant="primary"
            disabled={currentPage === 1}
            onClick={handlePrevPage}
          >
            <ArrowBackIosNewTwoToneIcon />
          </Button>
          <div className="page-info">
            <p>
              Page {currentPage} of {totalPages}
            </p>
          </div>
          <Button
            variant="primary"
            disabled={currentPage === totalPages}
            onClick={handleNextPage}
          >
            <ArrowForwardIosTwoToneIcon />
          </Button>
        </div>
      </div>
    </section>
  );
};

export default Recent;
