import { Close } from '@mui/icons-material'
import React, { useState } from 'react'
import { ToastContainer ,toast} from 'react-toastify'
import { setPass } from '../../api-helper/helper'
import '../registration/Reg.css'
import '../emailVerification/email.css'
import { useParams } from 'react-router-dom'

const SetNewPassword = (props) => {
    const [password,setPassword]=useState()
    const params=useParams();

    const [goback,setGoback]=useState(false)
    
    const handleSubmit= async (e)=>{
        e.preventDefault()
        console.log(password);
        console.log("above pass")
        console.log(params.id)

       const data = await setPass(password,params.id)
       if(data){
        setGoback(true)
        console.log("new set")
        console.log(data)
       
       }
       else {
        console.log("no new set")
       }
    }
  return (
    <div className='formcontainer'>

        {goback ?
        
        <div className='emailContainer'>
        <div className="emailBox">
            <div className="emailContent">
                  <h2>Email Sent Succesfully Please Reset your Password and ReLogin</h2>
                  <a className='emailLink' style={{fontSize:"30px"}} href="/login">Login here</a>
            </div>
        </div>
      </div>

      :

      <form onSubmit={handleSubmit} className='Authform'>
            <div className='forminputs'>

            <div className="closeicon">
                        <span onClick={()=>window.location.href='/login'}><Close sx={{cursor:"pointer"}}/></span>
            </div>
                
                <h1>Set New Password</h1>
                
            <div className="emailinp">
                <label ><b>New Password</b></label>
            </div>
            <input className='inputText' type="text" placeholder="Enter Password" onChange={(e)=>setPassword(e.target.value)} value={password} name="password" id="password" required/>

           

            <div className="signin">
                <button type="submit" className='btn'>Set Password</button>
                
            </div>
            
            
            

            </div>

            < ToastContainer/>

    
        </form>
    }

        
    </div>
  )
}

export default SetNewPassword
