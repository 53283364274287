import React, { useState, useEffect } from "react";
import "./searchitem.css";
import "../searchlist.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditIcon from "@mui/icons-material/Edit";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { useNavigate } from "react-router-dom";
import { deleteApartmentByID } from "../../api-helper/apartment";
import { useAuthContext } from "../../context/hook/useAuthContext";
import FavoriteIcon from "@mui/icons-material/Favorite";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import EmailIcon from "@mui/icons-material/Email";
import FavoriteBorderTwoToneIcon from "@mui/icons-material/FavoriteBorderTwoTone";
import { Icon, IconButton } from "@mui/material";

import AwesomeSlider from "react-awesome-slider";
import "react-awesome-slider/dist/styles.css";
import MapContainer from "./MapContainer";
import { Link } from "react-router-dom";

const Searchitem = (props) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  const handleedit = (id) => {
    navigate(`/editmyproperty/${id}`);
  };

  const handledelete = (id) => {
    console.log("delete");
    deleteApartmentByID(JSON.parse(localStorage.getItem("user")).token, id)
      .then((res) => {
        console.log("deleted successfully");
        console.log(res);
        window.location.reload();
      })
      .catch((err) => {
        console.log("err in deleting property");
        console.log(err);
      });
  };

  const [isFavorite, setIsFavorite] = useState(false);
  const [iconColor, setIconColor] = useState("red");

  const handleButtonClick = () => {
    setIsFavorite(!isFavorite);
    setIconColor(isFavorite ? "red" : "inherit");
  };

  const [images, setImages] = useState([]);

  useEffect(() => {
    if (props && props.property && props.property.img_links) {
      // setImages(props.property.img_links);
      let data = [];
      for (let image of props.property.img_links) {
        data.push({ source: image });
      }

      setImages(data);
    }
  }, [props.property]);

  const [currUser, setCurrUser] = useState("");
  useEffect(() => {
    if (user && user.existingUser && user.existingUser.email)
      setCurrUser(user.existingUser.email);
  }, [user]);

  return (
    <>
      <div className="card">
        <div className="siImg" style={{ backgroundColor: "lightgray" }}>
          <div style={{ maxWidth: "400px", margin: "auto" }}>
            <Link to={`/apartment/${props.property._id}`}>
              <AwesomeSlider media={images}></AwesomeSlider>
            </Link>
          </div>
          {/* <img src={props.property.img_link} alt="Apartment View" /> */}
        </div>
        {props.configuration &&
          props.configuration.mapVisible &&
          props.property.geoLocation && (
            <div style={{ marginTop: "-60px", zIndex: 100 }}>
              <MapContainer
                lat={props.property.geoLocation.coordinates[1]}
                lng={props.property.geoLocation.coordinates[0]}
                zoom={11}
              />
            </div>
          )}
        {/* https://media.designcafe.com/wp-content/uploads/2019/12/17055334/minimalistic-living-room-interior.jpg */}
        <div className="siInfo" style={{ padding: "5px" }}>
          <span
            className="type"
            style={{
              padding: "5px",
              background:
                props.property.type_of_property === "Sale"
                  ? "#25b5791a"
                  : "#ff98001a", //props.property.type_of_property
              color:
                props.property.type_of_property === "Sale"
                  ? "#25b579"
                  : "#ff9800",
            }}
          >
            {" "}
            {props.property.type_of_property
              ? props.property.type_of_property
              : "NA"}
          </span>
          <span>
            Area - {props.property.area ? props.property.area : "Not Available"}{" "}
          </span>
          <p>
            {props.property.No_of_bedroom ? props.property.No_of_bedroom : "NA"}
          </p>
          {(props.myproperty ||
            process.env.REACT_APP_ADMIN.split(", ").includes(currUser)) && (
            <div className="editdeleteicons">
              <span
                onClick={() => handleedit(props.property._id)}
                style={{
                  cursor: "pointer",
                  marginRight: "10px",
                  color: "#ce1515",
                }}
              >
                <EditIcon />
              </span>
              <span
                onClick={() => handledelete(props.property._id)}
                style={{
                  cursor: "pointer",
                  marginLeft: "10px",
                  color: "#ce1515",
                }}
              >
                <DeleteForeverIcon />
              </span>
            </div>
          )}
        </div>
        <p
          style={{
            color: "#4c4c4c",
            fontFamily: "CostarBrownLight,sans-serif",
            textAlign: "center",
            fontWeight: "bold",
            margin: "8px",
          }}
        >
          {props.property.name}
        </p>{" "}
        <p style={{ fontSize: "16px", display: "flex", alignItems: "center" }}>
          {" "}
          <span>
            <LocationOnIcon style={{ color: "#4c4c4c", margin: "auto" }} />
          </span>{" "}
          <span
            style={{
              color: "#4c4c4c",
              fontFamily: "CostarBrownLight,sans-serif",
            }}
          >
            {props.property.location}
          </span>{" "}
        </p>
        <h4
          className="siInfoType"
          style={{
            margin: "5px 15px",
            padding: "5px",
            fontFamily: "CostarBrownRegular,sans-serif",
            fontSize: "0.9rem",
          }}
        >
          {props.property.featured_type}
        </h4>
        <div className="sibtn">
          <button className="btnn">{props.property.price}</button>
          {/* <a className='a_btnn' href={props.property.web_link}>More Info</a>  */}
        </div>
        {props.home ? (
          <div className="contactbtn">
            <a className="contactLink" href={"/login"}>
              Contact
            </a>
            <IconButton onClick={handleButtonClick}>
              <Icon>
                {isFavorite ? (
                  <FavoriteIcon style={{ color: "red" }} />
                ) : (
                  <FavoriteBorderTwoToneIcon />
                )}
              </Icon>
            </IconButton>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              marginBottom: "10px",
              padding: "0px 10px",
            }}
          >
            {/* <p className='phoneInfo' style={{color:"#268d1f",padding:"0px 2px"}}>1234567890</p>
                    <p  className='emailInfo' style={{color:"#268d1f",padding:"0px 2px"}}>demo@gmail.com</p> */}
            <p style={{ color: "#268d1f", padding: "0px 2px" }}>
              {" "}
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <PhoneAndroidIcon
                  style={{ color: "rgb(67 67 67 / 82%)", padding: "0px 3px" }}
                />{" "}
                <span className="phoneLink">
                  {props.property.phone ? props.property.phone : "NA"}
                </span>{" "}
              </span>{" "}
            </p>
            <p style={{ color: "#268d1f", padding: "0px 2px" }}>
              {" "}
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <EmailIcon
                  style={{ color: "rgb(67 67 67 / 82%)", padding: "0px 5px" }}
                />{" "}
                <span className="emailLinkk">
                  {props.property.email ? props.property.email : "NA"}
                </span>{" "}
              </span>{" "}
            </p>
          </div>
        )}
      </div>

      {/* <div className="card">
            <div className="siImg">
            <img src="https://th.bing.com/th/id/OIP.qLVYj_t-HU2Yyx3v_wFgLwHaE6?pid=ImgDet&rs=1" alt="" />   
            </div>
            
            <div className="siInfo">
                <span className='type' style={{background: "#ff98001a",color:"#ff9800"}}> For Rent </span>
                <p>Willo</p>
            </div>
                <h2>Apartment</h2>
                <p>Location</p>
            <div className="sibtn">
            <button className='btnn more'>More</button>
            </div>
        </div> */}
    </>
  );
};

export default Searchitem;
