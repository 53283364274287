import React from 'react'
import award from'./award.css'
import {work} from '../featuredData'
import WorkIcon from '@mui/icons-material/Work';

const Award = () => {
  return (
    <section className='awards padding' id='Achievments'>
        <div className="container">
            <div className="awardtitle">
                <p>Our Work</p>
                <h1>Over 10,000+ Happy User Bieng With Us Still They Love Our Services</h1>
            </div>
            <div className="content grid4 mtop mbottom">
                {work.map((val,index)=>(
                    <div className="box" key={index}>
                        <div className="icon">
                            <div className="workicon">
                                <span><WorkIcon/></span>
                            </div>
                        </div>
                        <h1>{val.num}</h1>
                        <p>{val.name}</p>
                    </div>
                ))}
            </div>
        </div>

    </section>
  )
}

export default Award
