import React, { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { setPass } from '../../api-helper/helper'
import { ToastContainer } from 'react-toastify';
import { Close } from '@mui/icons-material';

const Resetpassword = () => {

    const [resetpass,setResetpass]=useState("");
    const navigate=useNavigate();
    const params=useParams();
    console.log(params.id);

    const [goback,setGoback]=useState(false);
    // console.log(localStorage.getItem('newpass'));
    const handleSubmit= async (e)=>{
        e.preventDefault()
        console.log(resetpass);
        console.log("above email")
        // params.token
       const data = await setPass(resetpass,params.token)
       if(data){
        console.log("new set")
        console.log(data)

        localStorage.setItem('newpass',true)

        // props.setNewpasspage(true)
        setGoback(true)
       
       }
       else {
        console.log("no forgot data")
       }
    }
   
    

  return (
    <div className='formcontainer'>

      <h1>Hello new pass here</h1>

      <div>
        <input type="text" placeholder='enter pass' />
      </div>

        {/* { goback ?   
            <div className='emailContainer'>
              <div className="emailBox">
                  <div className="emailContent">
                        <h2>Email Sent Succesfully Please Reset your Password and ReLogin</h2>
                        <a className='emailLink' style={{fontSize:"30px"}} href="/login">Login here</a>
                  </div>
              </div>
            </div>
        :    

         <form onSubmit={handleSubmit} className='Authform'>
        <div className='forminputs'>

        <div className="closeicon">
                    <span onClick={()=>window.location.href='/login'}><Close sx={{cursor:"pointer"}}/></span>
              </div>
            
            <h1>Reset New Password</h1>
            
        <div className="emailinp">
            <label>New Password</label>
        </div>
        <input className='inputText' type="text" placeholder="Enter Email" onChange={(e)=>setResetpass(e.target.value)} value={resetpass} name="resetpass" id="resetpass" required/> 

       

         <div className="signin">
            <button type="submit" className='btn'>Set New Pass</button>
            
        </div>
        
        
        

        </div>

        < ToastContainer/>


        </form>
        
         } 

*/}
       
    </div> 
  )
}
  

export default Resetpassword
