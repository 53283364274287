import { Close } from '@mui/icons-material'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ToastContainer,toast } from 'react-toastify'
import { sendForgotlink } from '../../api-helper/helper'
import '../registration/Reg.css'
import '../emailVerification/email.css'


const ForgetPass = (props) => {

    const [forgotEmail,setforgotEmail]=useState("")
    const navigate=useNavigate()

    const [goback,setGoback]=useState(false)

    const handleSubmit= async (e)=>{
        e.preventDefault()
        console.log(forgotEmail);
        console.log("above email")

       const data = await sendForgotlink(forgotEmail)
       if(data){
        console.log("forgpt data fe")
        console.log(data)

        localStorage.setItem('newpass',forgotEmail)

        props.setNewpasspage(true)
        setGoback(true)
       
       }
       else {
        console.log("no forgot data")
       }
    }

  return (
    <div className='formcontainer'>

        { goback ?   
            <div className='emailContainer'>
              <div className="emailBox">
                  <div className="emailContent">
                        <h2>Email Sent Succesfully Please Reset your Password and ReLogin</h2>
                        <a className='emailLink' style={{fontSize:"30px"}} href="/login">Login here</a>
                  </div>
              </div>
            </div>
        :    

        <form onSubmit={handleSubmit} className='Authform'>
        <div className='forminputs'>

        <div className="closeicon">
                    <span onClick={()=>window.location.href='/login'}><Close sx={{cursor:"pointer"}}/></span>
              </div>
            
            <h1>Forgot Password</h1>
            
        <div className="emailinp">
            <label ><b>Email</b></label>
        </div>
        <input className='inputText' type="text" placeholder="Enter Email" onChange={(e)=>setforgotEmail(e.target.value)} value={forgotEmail} name="email" id="email" required/>

       

        <div className="signin">
            <button type="submit" className='btn'>Send Link</button>
            
        </div>
        
        
        

        </div>

        < ToastContainer/>


        </form>
        
        }


       
    </div>
  )
}

export default ForgetPass
