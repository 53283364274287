import React from 'react'
import { useNavigate } from 'react-router-dom'

const TokenExpired = () => {
    const navigate=useNavigate()
    const handleclick=()=>{
        navigate("/login")
      }
  return (
    <div className='emailContainer'>
      <div className="emailBox">
          <div className="emailContent">
                <h2>Sorry...Token Expired</h2>
                <a className='emailLink' style={{fontSize:"30px"}} onClick={handleclick}>Go To Login</a>
          </div>
      </div>
    </div>
  )
}

export default TokenExpired
