// Import the functions you need from the SDKs you need
import { getAnalytics } from "firebase/analytics";
import { getAuth } from "firebase/auth";
import firebase from "firebase/compat/app";
import { getDatabase } from "firebase/database";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCf20ki4nWGtnyVuy0ySjYf6CQr6cSKoVc",
  authDomain: "apartments-sale.firebaseapp.com",
  projectId: "apartments-sale",
  storageBucket: "apartments-sale.appspot.com",
  messagingSenderId: "712744424343",
  appId: "1:712744424343:web:4cf2789ed3751627252ad2",
  measurementId: "G-J6ZCJ2PF22",
  databaseURL: "https://apartments-sale-default-rtdb.firebaseio.com",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
export const auth = getAuth();
export const database = getDatabase(app);
const analytics = getAnalytics(app);
