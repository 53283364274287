import logo from "./logo.svg";
import "./App.css";
import Header from "./header/Header.jsx";
import {
  Route,
  BrowserRouter as Router,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "./home/Home";
import Searchlist from "./searchlist/Searchlist";
import Registration from "./Auth/registration/Registration";
import Login from "./Auth/login/Login";
import AddProperty from "./home/addProperty/AddProperty";
import { useAuthContext } from "./context/hook/useAuthContext";
import "@fortawesome/fontawesome-free/css/all.min.css";
import EmailVerified from "./Auth/emailVerification/EmailVerified";
import Notverified from "./Auth/emailVerification/Notverified";
import ForgetPass from "./Auth/forgetpass/ForgetPass";
import SetNewPassword from "./Auth/setNewPass/SetNewPassword";
import { useState, useEffect } from "react";
import TokenExpired from "./Auth/forgetpass/TokenExpired";
import MyProperty from "./home/myProperty/MyProperty";
import EditProperty from "./home/addProperty/EditProperty";
import Resetpassword from "./Auth/setNewPass/Resetpassword";
import MoreInfo from "./home/moreInfo";
import Contact from "./home/contact";
import AddProject from "./home/addBuilderProject";
import PropertyAgent from "./home/propertyAgent";
import ChatbotFile from "./chatbot/ChatbotFile.js"
import Filterlist from "./searchlist/Filterlist.jsx";
import { Helmet } from 'react-helmet';
import Mapview from "./searchlist/mapview/Mapview.jsx";
import Profile from "./profile/Profile.jsx";
import Apartment from "./apartment/Apartment.jsx";

function App() {
  const { user } = useAuthContext();

  const [verificationpage, setVerificationpage] = useState(false);
  const [newpasspage, setNewpasspage] = useState(false);

  const [configuration, setConfiguration] = useState(null);
  const getData = async () => {
    const response = await fetch(`./config.json`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const res = await response.json();
    if (response.ok) {
      console.log("Data got successfully!", res);
      setConfiguration(res);
    } else {
      console.error("Failed to get data. Server response:", res);
    }
  };

  useEffect(() => {
    getData();
  }, [])
  // console.log(configuration);

  return (
    <Router>
      <ChatbotFile />
      <Routes>
        <Route
          path="/"
          element={user ? <Navigate to={"/searchlist"} /> : <Home configuration={configuration} />}
        />
        <Route
          path="/login"
          element={user ? <Navigate to={"/searchlist"} /> : <Login configuration={configuration} />}
        />
        <Route
          path="/register"
          element={
            user ? (
              <Navigate to={"/searchlist"} />
            ) : (
              <Registration setVerificationpage={setVerificationpage} configuration={configuration} />
            )
          }
        />

        {/* <Route path="/searchlist" element={<Searchlist />} /> */}
        <Route path="/more-info" element={<MoreInfo configuration={configuration} />} />
        <Route path="/contact-us" element={<Contact configuration={configuration} />} />
        <Route path="/property-agent" element={<PropertyAgent configuration={configuration} />} />
        <Route
          path="/searchlist"
          element={user ? <Searchlist configuration={configuration} /> : <Navigate to={"/"} />}
        />
        <Route
          path="/addproperty"
          element={
            localStorage.getItem("user") ? (
              <AddProperty configuration={configuration} />
            ) : (
              <Navigate to={"/login"} />
            )
          }
        />
        <Route
          path="/myproperties"
          element={
            localStorage.getItem("user") ? (
              <MyProperty configuration={configuration} />
            ) : (
              <Navigate to={"/login"} />
            )
          }
        />
        <Route
          path="/addprojects"
          element={
            localStorage.getItem("user") ? (
              <AddProject configuration={configuration} />
            ) : (
              <Navigate to={"/login"} />
            )
          }
        />
        <Route
          path="/editmyproperty/:id"
          element={
            localStorage.getItem("user") ? (
              <EditProperty configuration={configuration} />
            ) : (
              <Navigate to={"/login"} />
            )
          }
        />

        <Route
          path="/forgotPassword"
          element={<ForgetPass setNewpasspage={setNewpasspage} />}
        />

        <Route path="/resetpassword/:id" element={<Resetpassword />} />
        <Route path="/setNewPassword/:id" element={<SetNewPassword />} />
        <Route path="/tokenexpired" element={<TokenExpired />} />

        {verificationpage && (
          <Route
            path="/emailverified"
            element={
              <EmailVerified setVerificationpage={setVerificationpage} />
            }
          />
        )}
        {verificationpage && (
          <Route
            path="/Notverified"
            element={<Notverified setVerificationpage={setVerificationpage} />}
          />
        )}

        <Route path="/map" element={<Mapview />} />
        <Route
          path="/profile"
          element={localStorage.getItem("user") ? <Profile configuration={configuration} /> : <Navigate to={"/"} />}
        />
        <Route path="/apartment/:id" element={<Apartment configuration={configuration} />} />
        <Route path="/:location" element={<Filterlist configuration={configuration} />} />
      </Routes>
      {/* <Helmet>
        <title>Apartments for Sale - Find Your Dream Home</title>
        <meta name="description" content="Explore a variety of apartments for sale and find your dream home with our extensive listings. Discover the perfect living space for you and your family." />
        <meta name="keywords" content="apartments for sale, real estate, home listings, property, housing, buy apartment, real estate market" />
      </Helmet> */}
    </Router>
  );
}

export default App;
