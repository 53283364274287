import React, { useState } from "react";
import Login from "../Auth/login/Login";
import Header from "../header/Header";
import Award from "./awards/Award";
import Explore from "./explore/Explore";
import Featured from "./featured/Featured";
import Footer from "./footer/Footer";
import Recent from "./recent/Recent";
import Searchbar from "./searchbar/Searchbar";
import "./home.css";
import Registration from "../Auth/registration/Registration";
import { Helmet } from "react-helmet";

const Home = ({configuration}) => {
  const [login, setLogin] = useState(false);
  const [register, setRegister] = useState(false);

  const setAuth = (Auth, val) => {
    if (Auth === "Registration") {
      setRegister(val);
    } else {
      setLogin(val);
    }
  };

  return (
    <div className="home" id="Home">
      <Helmet>
        <meta
          name="description"
          content="Find your dream apartments today by browsing our site. Seearch all apartments in your area for sale and rent. Look by category - affordable, luxury , new. The best place to find home"
        />
        <meta
          name="keywords"
          content="apartements for sale, apartments for rent, dream apartments, search apartments, search home, browse houses"
        />
      </Helmet>

      <Header auth={setAuth} configuration={configuration}/>

      {/*       
          {login && 
            <div className='loginpage'>
              <Login auth={setAuth}/>
            
            </div>
          }
          {register && 
            <div className='registerpage'>
              <Registration auth={setAuth}/>
            </div>
          } */}

      <Searchbar />
      {/* <Featured/> */}
      {/* <Recent/> */}
      <Award />
      <Explore />
      <Footer />
    </div>
  );
};

export default Home;
