import React from "react";
import "./styles.css";
import { FaMapMarkerAlt } from "react-icons/fa";

const Marker = ({ text, tooltip, id }) => {
  const handleClick = () => {
    console.log(`You clicked on ${tooltip}`);
  };

  const generateColor = (id) => {
    const hash = id
      .split("")
      .reduce((acc, char) => char.charCodeAt(0) + acc, 0);

    // // Extracting components from the hash
    // const red = (((hash & 0x00ff00) >> 4) % 26) + 230;
    // const green = ((hash & 0x00ff00) >> 4)%120;
    // const blue = (hash & 0x0000ff)%120;

    // // Adjusting alpha based on the hash value
    // const alpha = ((hash % 21) + 80) / 100; // Normalize hash to a value between 0.8 and 1

    // const color = `rgba(${red}, ${green}, ${blue}, ${alpha})`;
    // return color;
    const markerColors = [
      "#920ffc",
      "#539987",
      "#A53860",
      "#1C77C3",
      "#40BCD8",
      "#F39237",
      "red",
      "green",
      "blue",
      "yellow",
      "orange",
    ];
    return markerColors[hash % 11];
  };

  return (
    <div className="circle" onClick={handleClick} title={text + ", " + tooltip}>
      <FaMapMarkerAlt size={31} color={generateColor(id)} />
      {/* <span className="circleText">{text}</span> */}
    </div>
  );
};

export default Marker;
