import React, { useState } from 'react';
import '../../chatbot.css'
import { getAllApartments } from '../../../api-helper/apartment';

const getData = (data) => {
  let res = [], resultStr = "";
  for (let item of data) {
    resultStr += "Name: " + item.name + ",   Price: " + item.price + ". \n\n";
    res.push(item);
  }

  if (resultStr.length == 0) {
    resultStr = "No data found";
  }

  return { json: res, str: resultStr };
}

const MessageParser = ({ children, actions }) => {
  const [filter, setFilter] = useState({ city: null, bedrooms: null });
  const { checker } = children.props.state;

  const parse = (message) => {
    if (checker == undefined || checker == "city") {
      if (message == undefined || message.length < 1) {
        actions.botMessage("Please enter a valid city name.", "city")
      }
      else {
        setFilter({ ...filter, city: message })
        console.log("city: ", message);
        actions.botMessage("How many bedrooms do you want in your apartment?", "bedroom")
      }
    }
    else if (checker === "bedroom") {
      if (message == undefined || message.length < 1 || !parseInt(message) || parseInt(message) <= 0) {
        actions.botMessage("Number of bedrooms should be a valid integer and at least one.", "bedroom")
      }
      else {
        setFilter({ ...filter, bedrooms: message });
        console.log("bedroom: ", message);
        getAllApartments({
          filter: { location: filter.city, bedroom: parseInt(message) },
          page: 1,
          limit: 5,
        }).then((res) => {
          let listData = getData(res.data);
          for(let item of listData.json){
            actions.botMessage(`Name: ${item.name},   Price: ${item.price}.,  bedrooms: ${item.No_of_bedroom}`,`apartments${item._id}`)
          }
          // actions.botMessage(listData.str, "apartmentsList");
          // actions.botMessage("In which city are you looking for an apartment?", "city");
        })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}

    </div>
  );
};

export default MessageParser;