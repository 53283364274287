import React, { useEffect, useState } from "react";
import Header from "../header/Header";
import Searchitem from "./searchitem/Searchitem";
import "./searchlist.css";
import "./searchitem/searchitem.css";
import { useAuthContext } from "../context/hook/useAuthContext";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllApartments } from "../api-helper/apartment";
import ArrowBackIosNewTwoToneIcon from "@mui/icons-material/ArrowBackIosNewTwoTone";
import ArrowForwardIosTwoToneIcon from "@mui/icons-material/ArrowForwardIosTwoTone";
import { Button } from "@mui/material";
import { TbBasketCancel } from "react-icons/tb";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

const Searchlist = ({configuration}) => {
  const { user, dispatch } = useAuthContext();
  const [loc, setLoc] = useState("");
  const [bedroom, setBedroom] = useState("");
  const [price, setPrice] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [currentCards, setCurrentCards] = useState([]);

  const navigate = useNavigate();
  const cardsPerPage = 10;
  const handlePrevPage = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };

  const handleNextPage = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const getApartments = () => {
    getAllApartments({
      filter: { location: loc, bedroom: parseInt(bedroom), price },
      page: currentPage,
      limit: cardsPerPage,
    })
      .then((res) => {
        // console.log(res.data);
        setCurrentCards(res.data);
        setTotalItems(res.totalItems);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getData = () => {
    fetch("./config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        // console.log(response)
        const res = response.json();
        return res;
      })
      .then(function (res) {
        setLoc(res.location);
        getAllApartments({
          filter: { location: res.location, bedroom: parseInt(bedroom), price },
          page: 1,
          limit: cardsPerPage,
        })
          .then((res) => {
            setCurrentCards(res.data);
            setTotalItems(res.totalItems);
          })
          .catch((err) => {
            console.log(err);
          });
      });
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    getApartments();
  }, [user, dispatch, currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(totalItems / cardsPerPage);

  return (
    <div>
      <Header configuration={configuration}/>
      <div className="listcontainer">
        <div className="listwrapper">
          <div className="listsearch">
            <h1 className="lstitle">Search</h1>
            <div className="serachData sd">
              <div className="listitem item">
                <label htmlFor="">Location</label>
                <input
                  type="text"
                  placeholder="Location"
                  value={loc}
                  onChange={(e) => setLoc(e.target.value)}
                />
              </div>
              <div className="listitem">
                <label htmlFor="">Bedrooms</label>
                <input
                  type="number"
                  min="1"
                  value={bedroom}
                  placeholder="No of Bedrooms"
                  onChange={(e) => setBedroom(e.target.value)}
                />
              </div>
              {/* <div className="listitem">
                <label htmlFor="">Price</label>
                <input
                  placeholder="Price"
                  onChange={(e) => setPrice(e.target.value)}
                />
              </div> */}
              <button
                className="btnSearch"
                onClick={() => {
                  setCurrentPage(1);
                  getApartments();
                }}
              >
                Search
              </button>
            </div>
          </div>
          {currentCards.length > 0 ? (
            <div className="listresult">
              <button
                className="btnSearch"
                style={{ marginTop: "25px", marginLeft: "20px" }}
                onClick={() => {
                  navigate("/map", { state: currentCards });
                }}
              >
                Explore these apartments on map
              </button>
              <div className="searchItem">
                {currentCards.map((val, ind) => (
                  <div key={ind}>
                    <Searchitem
                      property={val}
                      myproperty={false}
                      home={false}
                      configuration={configuration}
                    />
                  </div>
                ))}
              </div>
              <div className="pagination-buttons">
                <Button
                  variant="primary"
                  disabled={currentPage === 1}
                  onClick={handlePrevPage}
                >
                  <ArrowBackIosNewTwoToneIcon />
                </Button>
                <div className="page-info">
                  <p>
                    Page {currentPage} of {totalPages}
                  </p>
                </div>
                <Button
                  variant="primary"
                  disabled={currentPage === totalPages}
                  onClick={handleNextPage}
                >
                  <ArrowForwardIosTwoToneIcon />
                </Button>
              </div>
            </div>
          ) : (
            <div className="listresult not-found">
              <TbBasketCancel size={100} />
              <p style={{ margin: "8px", fontSize: "1.5rem" }}>
                No results found
              </p>
            </div>
          )}
        </div>
      </div>
      <ToastContainer />
      <Helmet>
        <title>Apartments Search Results - Find Your Dream Home</title>
        <meta
          name="description"
          content="Explore the latest apartments for sale in our search results. Use advanced filters to find your ideal home based on location, size, and amenities. Start your journey to homeownership today."
        />
        <meta
          name="keywords"
          content="apartments for sale, real estate search, property listings, home search, apartment filters, housing options, real estate market"
        />
      </Helmet>
    </div>
  );
};

export default Searchlist;
