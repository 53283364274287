import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import searchbar from "./searchbar.css";
import { useNavigate } from "react-router-dom";
import Recent from "../recent/Recent";
import Featured from "../featured/Featured";
import { HashLink } from "react-router-hash-link";
import { getAllApartments } from "../../api-helper/apartment";
import { useAuthContext } from "../../context/hook/useAuthContext";
import axios from "axios";

const Searchbar = () => {
  const navigate = useNavigate();
  const { user, dispatch } = useAuthContext();
  const [allProperty, setAllProperty] = useState([{}]);

  const [loc, setLoc] = useState("");
  const [price, setPrice] = useState("");
  const [bedrooms, setBedrooms] = useState("");

  const query = new URLSearchParams(window.location.search);
  console.log(query);
  console.log(query.get("user"));
  console.log(query.get("price"));

  useEffect(() => {
    getAllApartments({filter:{},page:0,limit:50})
      .then((res) => {
        console.log("all property received");
        console.log(res.data);
        setAllProperty(res.data);
      })
      .catch((err) => {
        console.log("err in fetching all apartment ");
      });

    const getSearches = async () => {
      console.log(user.existingUser);

      const res = await axios
        .get(
          "http://apartments-env.eba-ver32ppm.us-east-1.elasticbeanstalk.com/api/user/searchlist",
          {
            headers: {
              authorization: `Bearer ${user.token}`,
            },
          }
        )
        .catch((err) => console.log("Error occured "));

      if (res.status !== 200 && res.status !== 201) {
        return console.log("No success");
      }

      const data = await res.data;
      console.log(data);
    };

    getSearches();
  }, [user, dispatch]);

  return (
    <>
      <section className="searchbar">
        <div className="container">
          <div className="heading">
            <h1>Search Your Next Home</h1>
            <p>Find new and featured property located in your city</p>
          </div>
          {/* <Heading title="Search Your Next Home" subtitle="Find new and featured property located in your city"/> */}
          <form className="flex searchform">
            <div className="searchitem">
              <span>Location</span>
              <input
                type="text"
                placeholder="city/street name"
                onChange={(e) => setLoc(e.target.value)}
              />
            </div>
            <div className="searchitem ">
              <span>Bedrooms</span>
              <input
                type="text"
                placeholder="No of Bedrooms"
                onChange={(e) => setBedrooms(e.target.value)}
              />
            </div>
            <div className="searchitem">
              <span>Price</span>
              <input
                type="text"
                placeholder="Enter Price"
                onChange={(e) => setPrice(e.target.value)}
              />
            </div>
            {/* <div className="searchitem box2">
                <span>Advance Filter</span>
                <input type="text" placeholder=''/>
            </div> */}
            <div className="sbtn">
              {/* <button onClick={handleSearch} >Search</button> */}
              <HashLink className="button" to={"#About"}>
                Search
              </HashLink>
            </div>
          </form>
        </div>
      </section>
      <Featured />
      <Recent searchLoc={loc} searchPrice={price} searchBedroom={bedrooms} />
    </>
  );
};

export default Searchbar;
